@use "global";
@import "/assets/css/common.css";

.mainimg {
  margin-bottom: 80px;
}

.pharma-net {
  margin-bottom: 68px;
}

.network {
  margin: 40px 0 0 0;
}

.related-business {
  margin: 40px 0 36px 0;
}


/*「お知らせ」
---------------------------------------------------------------------------*/
.news {
  width: 100%;
  max-width: 1025px;
  margin: auto auto 68px auto;

  .news-container {
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    margin-bottom: 26px;

    h2 {
      flex-basis: 25%;
      margin: 0 0 30px 0;
      text-align: left;
      white-space: nowrap;
    }

    dl {
      display: flex;
      flex-basis: 75%;
      flex-flow: row wrap;
      width: 100%;
      margin: 0;

      dt,
      dd {
        padding: 1em 0;
        border-bottom: 1px solid #C0C0C0;
      }

      dt:first-child,
      dt:first-child + dd {
        border-top: 1px solid #C0C0C0;
      }

      dt {
        flex-basis: 8.2em;
      }

      dd {
        flex-basis: calc(100% - 8.2em);
      }
    }
  }
}


/*---------------------------------------------------------------------------
画面幅768px以下
---------------------------------------------------------------------------*/
@media screen and (max-width: 768px) {

  #contents {
    img {
      width: 100%;
    }
  }

  article:nth-child(2) {
    h2 {
      margin-bottom: 50px;
    }
  }

  .mainimg {
    margin-bottom: 30px;
  }

  .pharma-net {
    margin-bottom: 34px;
  }

  .network {
    margin: 40px 0 0 0;
  }

  .related-business {
    margin: 40px 0 36px 0;
  }

  .news {

    .news-container {
      display: block;

      h2 {
        margin: 0 0 10px 0;
      }

      dl {
        flex-flow: column;
        width: 100%;
        border: none;
        margin-bottom: 40px;

        dt,
        dd {
          padding: 0;
          border: none;
        }

        dt:first-child,
        dt:first-child + dd {
          border-top: none;
        }

        dt {
          flex-basis: inherit;
          border: none;
          padding: 1em 0 0 0;
          line-height: 1.4;
        }

        dd {
          border-bottom: 1px solid #C0C0C0;
          padding: 0.5em 0;
        }
      }

    }

  }

  .btn-arrow {
    text-align: center;
  }

}